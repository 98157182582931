import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { NEXT_PUBLIC_CDN_ROOT } from '../../cfg';

import styles from './Picture.module.css';

const Picture = (props) => {
  const {
    picture,
    className,
    imgClassName,
    isAbsolutePath,
    style,
    imgStyle,
    imgAttributes,
  } = props;

  if (!picture) {
    return null;
  }

  const imgAttrs = imgAttributes || {};

  const alt = picture?.alt || picture?.caption;
  const src = picture?.src;
  const sources = picture?.source;

  return (
    <picture className={classnames(styles.picture, className)} style={style}>
      {sources?.map((item, i) => {
        const itemMedia = item?.media;
        const itemSrcSetItems = item?.srcset;
        const itemSrcSet = Array.isArray(itemSrcSetItems) ? itemSrcSetItems
          .map(itemSrcSetItem => `${isAbsolutePath ? '' : NEXT_PUBLIC_CDN_ROOT}${itemSrcSetItem?.url}${itemSrcSetItem?.density ? ` ${itemSrcSetItem?.density}` : ''}`)
          .join(', ') : null;

        return (
          <source key={`source-${i}`} srcSet={itemSrcSet} media={itemMedia} />
        );
      })}
      <img
        alt={alt}
        src={`${isAbsolutePath ? '' : NEXT_PUBLIC_CDN_ROOT}${src}`}
        style={imgStyle}
        className={imgClassName}
        {...imgAttrs}
      />
    </picture>
  );
};

Picture.propTypes = {
  picture: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.any,
  imgStyle: PropTypes.any,
  imgAttributes: PropTypes.any,
  sourcePath: PropTypes.string,
  isAbsolutePath: PropTypes.bool,
};

Picture.defaultProps = {
  className: null,
  sourcePath: 'source',
  isAbsolutePath: false,
};

export default Picture;
